import React, { useEffect, useState } from "react"
import { Row, Container } from "react-bootstrap"
import { SRLWrapper } from "simple-react-lightbox"
import InnerWrap from "../components/_Wrap/inner-wrap"
import Hero, { FluidHero } from "../components/Hero"

import Paragraph from "../components/Paragraph"
import SEO from "../components/seo"
import Portfolio from "../components/Portfolio"
import { makeLink } from "../utils/google-pic-format"
import CTA from "../components/CTA"
import { graphql } from "gatsby"
const LargeScaleInstallations = ({ data }) => {
  const { content, header } = data

  const [projects, setProjects] = useState([])
  useEffect(() => {
    let projectArray = []
    let num = -1
    let d = 0
    content.edges.forEach((con, i) => {
      //first build an array of the titles

      let potentialProject = {}
      if (
        con.node.largeScaleProjectTitle &&
        con.node.largeScaleProjectTitle !== null &&
        con.node.largeScaleProjectTitle.match(/^(?!\s*$).+/)
      ) {
        potentialProject["name"] = con.node.largeScaleProjectTitle
        potentialProject["description"] = con.node.largeScaleProjectDescription
        potentialProject["pictures"] = []
        potentialProject["index"] = num
        projectArray.push(potentialProject)
        //only increase the index here
        num++
      } else {
        const obj = projectArray[num]
        obj.pictures = [
          ...obj.pictures,
          {
            name: con.node.projectPictureTitle,
            link: makeLink(con.node.projectPictureLink),
            description: con.node.projectPictureDescription,
            index: d,
          },
        ]
        d++
      }
    })

    setProjects(projectArray)
  }, [content.edges])

  return (
    <React.Fragment>
      <SEO
        title="Large Scale Installations"
        keywords={[
          `installations`,
          `art projects`,
          `glass art`,
          `corporate installations`,
        ]}
      />

      {/* <Hero src={makeLink(header.edges[0].node.headerPicture)} /> */}
      <FluidHero
        name={`${encodeURIComponent(
          header.edges[0].node.headerPictureTitle
        )}.png`}
      ></FluidHero>
      <Container fluid>
        <Row>
          <InnerWrap>
            <Paragraph
              title={header.edges[0].node.pagetitle}
              subheading={header.edges[0].node.subtitle}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: header.edges[0].node.paragraph.replace(
                    /\n/g,
                    "<br />"
                  ),
                }}
              ></p>
            </Paragraph>
          </InnerWrap>
        </Row>

        <Row>
          <SRLWrapper style={{ margin: "auto !important" }}>
            {projects.map(project => (
              <Portfolio
                key={`portfolio--map--${project.name}`}
                title={project.name}
                description={project.description}
                pictures={project.pictures}
              />
            ))}
          </SRLWrapper>
        </Row>
        <Row>
          <CTA />
        </Row>
      </Container>
      <div style={{ height: "50px", backgroundColor: "white" }}></div>
    </React.Fragment>
  )
}
export const query = graphql`
  query InstallationsQuery {
    header: allGoogleSheetLargeScaleInstallationsRow {
      edges {
        node {
          headerPicture
          headerPictureTitle
          pagetitle
          paragraph
          subtitle
        }
      }
    }
    content: allGoogleSheetLargeScaleInstallationsRow {
      edges {
        node {
          largeScaleProjectTitle
          largeScaleProjectDescription
          projectPictureLink
          projectPictureTitle
          projectPictureDescription
        }
      }
    }
  }
`
export default LargeScaleInstallations
